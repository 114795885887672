<template>
  <vx-card>
    <div class="vx-col sm:w-1/1 w-full mb-base">
      <vs-tabs>
        <vs-tab label="Expired Report">
          <div class="tab-text">
            <!-- filter territory -->
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>OU</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedOus"
                  :options="optionOus"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customLabel"
                  track-by="id"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Territory</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedTerritories"
                  :options="optionTerritories"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customLabel"
                  track-by="id"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Date</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full flex items-center gap-4">
                <date-range-picker
                  ref="picker"
                  opens="center"
                  :singleDatePicker="false"
                  :timePicker="false"
                  :timePicker24Hour="false"
                  :showWeekNumbers="true"
                  :showDropdowns="true"
                  :autoApply="true"
                  v-model="datePicker"
                  class="w-full"
                >
                  <template v-slot:input="picker" style="width: 100%">
                    {{ dateFormat(picker.startDate) }} -
                    {{ dateFormat(picker.endDate) }}
                  </template>
                </date-range-picker>
                <vs-button color="danger" @click="clearDate()">X</vs-button>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Storage Area</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedStorageArea"
                  :options="optionStorageArea"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customLabel"
                  track-by="id"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Principal</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedPrincipal"
                  :options="optionPrincipal"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customNormalLabel"
                  track-by="id"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>SKU Code</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedSkuCode"
                  :options="optionSkuCode"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customNormalLabel"
                  track-by="id"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Batch Number</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedBatchNumber"
                  :options="optionBatchNumber"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customNormalLabel"
                  track-by="id"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Group ED</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedGroupEd"
                  :options="optionGroupEd"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customNormalLabel"
                  track-by="id"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Bucket Aging</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedBucketAging"
                  :options="optionBucketAging"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customNormalLabel"
                  track-by="id"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-button color="success" @click="handleDrawTable()"
                  >Get Data</vs-button
                >
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col md:w-1/1 w-full mb-base">
                <ExpiredTable
                  :draw="this.draw"
                  :territoryIDs="this.territoryIDs"
                  :ouIDs="this.ouIDs"
                  :storageAreaIDs="this.storageAreaIDs"
                  :principalIDs="this.principalIDs"
                  :skuCodeIDs="this.skuCodeIDs"
                  :batchNumberIDs="this.batchNumberIDs"
                  :groupEdIDs="this.groupEdIDs"
                  :bucketAgingIDs="this.bucketAgingIDs"
                  :startDate="this.datePicker.startDate"
                  :endDate="this.datePicker.endDate"
                />
              </div>
            </div>
          </div>
        </vs-tab>
        <vs-tab label="Export">
          <div class="tab-text">
            <DataTableExport />
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </vx-card>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import ExpiredTable from './ExpiredTable.vue';
import DataTableExport from './DataTableExport.vue';
import moment from 'moment';
import { mapActions, mapState } from 'vuex/dist/vuex.common.js';

export default {
  components: {
    ExpiredTable,
    DataTableExport,
    DateRangePicker,
  },
  data() {
    return {
      baseUrl: '/api/report/v1/territories',
      selectedTerritories: [],
      optionTerritories: [],
      selectedOus: [],
      optionOus: [],
      selectedStorageArea: [],
      optionStorageArea: [],
      selectedPrincipal: [],
      optionPrincipal: [],
      selectedSkuCode: [],
      optionSkuCode: [],
      selectedBatchNumber: [],
      optionBatchNumber: [],
      selectedGroupEd: [],
      optionGroupEd: [],
      selectedBucketAging: [],
      optionBucketAging: [],
      territoryIDs: [],
      territoryCodes: [],
      territoryNames: [],
      ouIDs: [],
      storageAreaIDs: [],
      principalIDs: [],
      skuCodeIDs: [],
      batchNumberIDs: [],
      groupEdIDs: [],
      bucketAgingIDs: [],
      draw: 0,
      datePicker: {
        startDate: null,
        endDate: null,
      },
    };
  },
  methods: {
    ...mapActions({
      getOu: 'reportExpired/getOu',
      getStorageArea: 'reportExpired/getStorageArea',
      getPrincipal: 'reportExpired/getPrincipal',
      getSkuCode: 'reportExpired/getSkuCode',
      getBatchNumber: 'reportExpired/getBatchNumber',
      getGroupEd: 'reportExpired/getGroupEd',
      getBucketAging: 'reportExpired/getBucketAging',
    }),
    customLabel(val) {
      if (val) {
        return val.code === '-' ? `${val.name}` : `(${val.code}) ${val.name}`;
      }
    },
    customNormalLabel(val) {
      if (val) {
        return val.name;
      }
    },
    handleClose() {
      this.detail = false;
    },

    handleOpen() {
      this.detail = true;
    },
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format('dddd, MMMM Do YYYY');
      }
      return a;
    },
    getTerritories() {
      this.$http.get('/api/report/v1/master/territory').then((resp) => {
        this.optionTerritories = resp.data.records;
      });
    },
    handleDrawTable() {
      this.draw++;
    },
    clearDate() {
      this.datePicker.startDate = null;
      this.datePicker.endDate = null;
    },
  },
  mounted() {
    this.getTerritories();
    this.getOu();
    this.getStorageArea();
    this.getPrincipal();
    this.getSkuCode();
    this.getBatchNumber();
    this.getGroupEd();
    this.getBucketAging();
  },
  watch: {
    selectedTerritories(val) {
      let territoryIDs = [];
      let territoryCodes = [];
      let territoryNames = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          territoryIDs.push(e.id);
          territoryCodes.push(e.code);
          territoryNames.push(e.name);
        }
      });

      this.territoryIDs = territoryIDs;
      this.territoryCodes = territoryCodes;
      this.territoryNames = territoryNames;
    },

    selectedOus(val) {
      let ouIDs = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          ouIDs.push(e.id);
        }
      });

      this.ouIDs = ouIDs;
    },

    selectedStorageArea(val) {
      let storageAreaIDs = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          storageAreaIDs.push(e.id);
        }
      });

      this.storageAreaIDs = storageAreaIDs;
    },
    selectedPrincipal(val) {
      let principalIDs = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          principalIDs.push(e.id);
        }
      });

      this.principalIDs = principalIDs;
    },
    selectedSkuCode(val) {
      let skuCodeIDs = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          skuCodeIDs.push(e.id);
        }
      });

      this.skuCodeIDs = skuCodeIDs;
    },
    selectedBatchNumber(val) {
      let batchNumberIDs = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          batchNumberIDs.push(e.id);
        }
      });

      this.batchNumberIDs = batchNumberIDs;
    },
    selectedGroupEd(val) {
      let groupEdIDs = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          groupEdIDs.push(e.id);
        }
      });

      this.groupEdIDs = groupEdIDs;
    },
    selectedBucketAging(val) {
      let bucketAgingIDs = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          bucketAgingIDs.push(e.id);
        }
      });

      this.bucketAgingIDs = bucketAgingIDs;
    },

    ouList(val) {
      this.optionOus = val;
    },
    storageAreaList(val) {
      if (val) {
        this.optionStorageArea = val;
      }
    },
    principalList(val) {
      this.optionPrincipal = val;
    },
    skuCodeList(val) {
      this.optionSkuCode = val;
    },
    batchNumberList(val) {
      this.optionBatchNumber = val;
    },
    groupEdList(val) {
      this.optionGroupEd = val;
    },
    bucketAgingList(val) {
      this.optionBucketAging = val;
    },
  },
  computed: {
    ...mapState({
      ouList: (state) => state.reportExpired.ouList,
      storageAreaList: (state) => state.reportExpired.storageAreaList,
      principalList: (state) => state.reportExpired.principalList,
      skuCodeList: (state) => state.reportExpired.skuCodeList,
      batchNumberList: (state) => state.reportExpired.batchNumberList,
      groupEdList: (state) => state.reportExpired.groupEdList,
      bucketAgingList: (state) => state.reportExpired.bucketAgingList,
    }),
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to

/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
